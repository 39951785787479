import { FormControl, Grid, Input, InputAdornment } from '@mui/material';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BackButton, CardBox } from '../../../../components';
import { errorAlert } from '../../../../helpers/alerts';
import { validatePermission } from '../../../../helpers/authentication';
import { updateSegment } from '../../../../redux/slices/client/bagSlice';
import { updateBagSegmentCommissionService, updateBagSegmentStateService } from '../../../../services/client/bagService';
import { setLoading } from 'redux/slices/uiSlice';

const BagTAESegmentScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control } = useForm();
  const { segments, currentOperator, currentRegion } = useSelector((store) => store.bags)
  const { currentCommerce } = useSelector((store) => store.commerces);
  const currentBag = currentCommerce.bags.find(bag => bag.type === 'TAE');
  const formatter = (item) => {
    return {
      id: item._id,
      idBagSegment: item.commerce?._id,
      name: item.name,
      image: `/v3/file/${item.fd}/`,
      commission: item.commerce?.commission,
      check: !!item.commerce,
    };
  };

  const handlerCheck = async (item, check) => {
    try {
      dispatch(setLoading(true));
      const response = await updateBagSegmentStateService(currentBag._id, currentOperator.id, currentRegion?.id, item.id, { state: check });
      dispatch(updateSegment({ id: item.id, data: { commerce: check ? response : null } }));
    }
    catch (error) {
      errorAlert(error)
    }
    finally {
      dispatch(setLoading(false));
    }
  }

  const handlerSubmit = async (item, commission) => {
    try {
      await updateBagSegmentCommissionService(currentBag._id, currentOperator.id, currentRegion?.id, item.id, {
        commission: commission
      });
      dispatch(updateSegment({ id: item.id, data: { commerce: { commission: commission } } }));
    } catch (error) {
      errorAlert(error)
    }
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton
            name={"Administrar productos"}
            click={() => navigate(-1)}
          />
        </div>
        <div className="container-title">
          <div className="title">Segmentos</div>
          <div className="subtitle">{currentBag.username}</div>
        </div>
        <div style={{ width: "170px" }}></div>
      </Grid>
      <CardBox
        name={"Selecciona segmentos"}
        values={segments}
        showBorder={false}
        showCheck={validatePermission('bolsas', 'bolsas', ['agregar', 'editar'])}
        showInput={true}
        checked={({ check }) => check}
        handleCheck={handlerCheck}
        formatter={formatter}
        element={(item) => (
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            {
              validatePermission('bolsas', 'bolsas', ['editar']) && currentBag.earnings === "VENTA" &&
              <Controller
                name={item.id}
                defaultValue={item.commission || 0}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <FormControl sx={{ m: 0, mt: 0, p: 0, width: '165px' }}  >
                    <Input
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      startAdornment={<InputAdornment position='start'>Comisión: </InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      type='number'
                      inputProps={{
                        'aria-label': 'weight',
                        'step': "0.01"
                      }}
                      style={{ color: '#ED3726' }}
                      value={value}
                      onChange={onChange}
                      onBlur={() => handlerSubmit(item, value)}
                      error={!!error}
                      helpertext={error && <span> {error.message}</span>}
                      disabled={!item.check || currentBag.earnings === 'compra' || !validatePermission('bolsas', 'bolsas', ['agregar', 'editar'])}
                    />
                  </FormControl>
                )}
              />
            }

          </Grid>
        )}
      />
    </>
  )
}

export default BagTAESegmentScreen