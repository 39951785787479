import service from "services/service";

export const getAlertMonitorService = () => (
  service('get', `v3/alertas/monitor`)
)

export const getAlertMonitorTxnsService = (id) => (
  service('get', `v3/alertas/monitor/${id}/transacciones`)
)

export const getAlertsService = (type) => (
  service('get', `v3/alertas/${type}`)
)

export const createAlertService = (data) => (
  service('post', `v3/alertas`, { data })
)

export const updateAlertService = (id, data) => (
  service('patch', `v3/alertas/${id}`, { data })
)

export const removeAlertService = (id) => (
  service('delete', `v3/alertas/${id}`)
)