import {
  Assessment,
  Circle,
  PointOfSale,
  RecentActors,
  Security,
  SettingsInputComponent,
} from "@mui/icons-material";
import { menuIcon } from "config/theme";
const menu = [
  {
    icon: 'query_stats',
    name: "Panel de Control",
    keys: ["Panel de Control"],
    page: "/panel/transacciones",
  },
  {
    icon: 'support_agent',
    name: "Soporte",
    keys: ["Proveedores", "Conciliaciones"],
    list: [
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'support_agent',
        name: "Monitor de Proveedores",
        keys: ["Proveedores"],
        page: "/admin/proveedores/monitor",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'support_agent',
        name: "Monitor de Servicios",
        keys: ["Proveedores"],
        page: "/admin/servicios/monitor",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'display_settings',
        name: "Alertas",
        keys: ["Proveedores"],
        page: "/admin/alertas/eventos",
      },
      {
        icon: 'fact_check',
        name: "Conciliaciones",
        keys: ["Conciliaciones"],
        list: [
          {
            icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
            iconTitle: 'fact_check',
            name: "Conciliaciones Reporte",
            keys: ["Conciliaciones"],
            page: "/admin/proveedores/conciliaciones",
          },
          {
            icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
            iconTitle: 'fact_check',
            name: "Conciliaciones Histórico",
            keys: ["Conciliaciones"],
            page: "/admin/proveedores/conciliaciones/history",
          },
        ],
      },
    ],
  },
  {
    icon: 'manage_accounts',
    name: "Administración",
    keys: ["Productos", "Proveedores", "Ruteo", "Conectores"],
    list: [
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'category',
        name: "Productos",
        keys: ["Productos"],
        page: "/admin/productos/tae",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'manage_accounts',
        name: "Proveedores",
        keys: ["Proveedores"],
        page: "/admin/proveedores",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'manage_accounts',
        name: "Carriers",
        keys: ["Proveedores"],
        page: "/admin/proveedores/carriers",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'manage_accounts',
        name: "Conectores",
        keys: ["Conectores"],
        page: "/admin/conectores",
      },
      // {
      //   icon: <Circle htmlColor='#ffffff' sx={{ width: 10, height: 10 }} />,
      // iconTitle: 'manage_accounts',
      //   name: 'Conciliaciones Reporte',
      //   keys: ['Conciliaciones', 'Reportes'],
      //   page: '/admin/proveedores/conciliaciones',
      // },
      // {
      //   icon: <Circle htmlColor='#ffffff' sx={{ width: 10, height: 10 }} />,
      // iconTitle: 'manage_accounts',
      //   name: 'Conciliaciones Histórico',
      //   keys: ['Conciliaciones', 'Reportes'],
      //   page: '/admin/proveedores/conciliaciones/history',
      // },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'manage_accounts',
        name: "Códigos de error",
        keys: ["Proveedores"],
        page: "/admin/proveedores/hebecodes",
      },
    ],
  },
  {
    icon: <SettingsInputComponent htmlColor={menuIcon} />,
    name: "Ruteo",
    keys: ["Ruteo", "Simulador"],
    list: [
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Ladas",
        iconTitle: 'settings_phone',
        keys: ["Ruteo"],
        page: "/ruteo/ladas",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Panel de Ruteo",
        iconTitle: 'display_settings',
        keys: ["Ruteo"],
        page: "/ruteo/panel/tae",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Simulador",
        iconTitle: 'terminal',
        keys: ["Ruteo"],
        page: "/ruteo/simulador",
      },
    ],
  },
  {
    icon: <Security htmlColor={menuIcon} />,
    name: "Seguridad",
    keys: ["Administradores", "Usuarios", "Roles", "Permisos"],
    list: [
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Administradores",
        iconTitle: 'admin_panel_settings',
        keys: ["Administradores"],
        page: "/seguridad/administradores",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Roles",
        iconTitle: 'perm_contact_calendar',
        keys: ["Roles"],
        page: "/seguridad/roles",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Permisos",
        iconTitle: 'lock_person',
        keys: ["Permisos"],
        page: "/seguridad/permisos",
      },
    ],
  },
  {
    icon: <RecentActors htmlColor={menuIcon} />,
    name: "Clientes",
    keys: ["Comercios", "Bolsas", "Puntos de Venta", "Grupos"],
    list: [
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'group',
        name: "Grupos",
        keys: ["Grupos"],
        page: "/cliente/grupos",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'storefront',
        name: "Comercios",
        keys: ["Comercios"],
        page: "/cliente/comercios",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Bolsas",
        iconTitle: 'bussiness_center',
        keys: ["Bolsas"],
        page: "/cliente/bolsas",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        iconTitle: 'point_of_sale',
        name: "Puntos de Venta",
        keys: ["Puntos de Venta"],
        page: "/cliente/puntos_de_venta",
      },
    ],
  },
  {
    icon: <Assessment htmlColor={menuIcon} />,
    name: "Reportes",
    keys: ["Detallado", "Saldos", "Reportes"],
    list: [
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Reporte detallado",
        iconTitle: 'plagiarism',
        keys: ["Detallado", "Reportes"],
        page: "/reportes/detallado",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Reporte de saldos",
        iconTitle: 'account_balance_wallet',
        keys: ["Saldos", "Reportes"],
        page: "/reportes/saldos",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Reporte de totales",
        iconTitle: 'plagiarism',
        keys: ["Totales", "Reportes"],
        page: "/reportes/totales",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Reporte de saldo diario",
        iconTitle: 'plagiarism',
        keys: ["SaldoDiario", "Reportes"],
        page: "/reportes/saldo_diario",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Reporte de bitacora",
        iconTitle: 'plagiarism',
        keys: ["Bitacora", "Reportes"],
        page: "/reportes/bitacora",
      },
      {
        icon: <Circle htmlColor={menuIcon} sx={{ width: 10, height: 10 }} />,
        name: "Reporte de red",
        iconTitle: 'plagiarism',
        keys: ["Red"],
        page: "/reportes/red",
      },
    ],
  },
  {
    icon: <PointOfSale htmlColor={menuIcon} />,
    name: "Venta",
    iconTitle: 'point_of_sale',
    keys: ["Venta"],
    page: "/venta",
  },
];

export const categories = menu.reduce(
  (prev, item) => [...prev, ...item.keys],
  []
);

export default menu;
