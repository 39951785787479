import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, TableData } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { setCredTAEProductService } from "services/admin/providerService";
import { setDenominations as setCredTaeProducts, setCurrentTAEProduct } from "redux/slices/admin/providerSlice";
import { Grid } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { setDenominations } from "redux/slices/admin/denominationSlice";
import { getDenominationBySegmentService } from "services/admin/denominationServices";

const CredTAEProductsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    providerCredSelected,
    currentOperator,
    currentSegment,
    denominations: credTaeProducts,
  } = useSelector((store) => store.providers);
  const { denominations } = useSelector((store) => store.denominations);

  useEffect(() => {
    getDenominationBySegmentService(currentSegment.id)
      .then(response => dispatch(setDenominations(response)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerCheck = async (row, state) => {
    setCredTAEProductService(providerCredSelected.id, currentOperator.id, currentSegment.id, row.id, !state).then((result) => {
      dispatch(setCredTaeProducts(result));
    }).catch(error => errorAlert(error))
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentTAEProduct(row));
    navigate('editar');
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">Denominaciones</div>
          <div className="bag">{currentSegment.name} </div>
        </div>
        <div>
        </div>
      </Grid>
      <TableData
        dataRows={denominations.map((item) => {
          const service = credTaeProducts?.find(({ idProduct }) => idProduct.id === item.id);
          return {
            ...item,
            service
          };
        })}
        dataTitle={["Carrier", "Monto", "ExposeId", "SKU", "Estado"]}
        formatter={({ imp, service, denomination }) => [
          currentSegment.carrierId,
          imp,
          `${currentSegment.code}${denomination}`,
          service?.sku,
          { name: "state", type: "boolean", value: service?.state, disabled: !service?.sku },
        ]}
        onChecked={handlerCheck}
        actions={[
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
        ]}
      />
    </>
  );
};

export default CredTAEProductsScreen;
