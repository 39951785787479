import React, { useEffect, useState } from 'react'
import { AddOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";

import { CustomButton, FilterBar, TableData } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { useDispatch, useSelector } from 'react-redux';
import { setLadas, updateLada, setCurrentLada, removeLada } from '../../../redux/slices/admin/ladasSlice';
import { getLadasService, getRegionsService, removeLadaService, updateLadaService } from '../../../services/admin/ladasService';
import { formatDateForTable } from '../../../helpers/util';
import { useNavigate } from 'react-router-dom';

import { validatePermission } from '../../../helpers/authentication';
import { setRegions } from 'redux/slices/admin/regionsSlice';

const LadasScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ladas } = useSelector((store) => (store.ladas))
  const { regions } = useSelector((store) => (store.regions))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRegionsService().then((result) =>
      dispatch(setRegions(result.sort((a, b) => a.nombre.localeCompare(b.nombre))))
    ).catch((error) => {
      errorAlert(error);
    });
    // eslint-disable-next-line
  }, []);

  const handlerSubmit = async ({ region }) => {
    setLoading(true);
    getLadasService(region == '' ? undefined : region).then((result) => {
      dispatch(setLadas(result.sort((a, b) => a.lada > b.lada ? 1 : -1)));
    }).catch((error) => {
      errorAlert(error);
    }).finally(() => setLoading(false));
  }


  const handlerCheck = async (row, currentValue) => {
    try {
      const value = !currentValue;
      await updateLadaService(row.id, { state: value });
      dispatch(updateLada({ id: row.id, data: { state: value } }));
    } catch (error) {
      errorAlert(error)
    }
  };

  const handlerDelete = ({ id, lada }) => {
    confirmAlert({
      title: "Estás seguro que deseas eliminar esta lada?",
      text: `Lada: ${lada} será eliminada.`,
      onConfirm: async () => {
        try {
          await removeLadaService(id);
          dispatch(removeLada(id));
        }
        catch (error) {
          errorAlert(error);
        }
      }
    })
  };

  const handlerClick = () => {
    navigate("crear");
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentLada(row));
    navigate("editar");
  };

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        filters={[
          {
            name: 'region',
            label: "Region",
            type: "select",
            all: 'Todas',
            data: regions.map(({ id, nombre }) => ({ id, name: nombre })),
          },
        ]}
      />
      <TableData
        dataRows={ladas}
        loading={loading}
        headerButtons={[
          //   <CustomButton
          //   icon={<SaveAltIcon />}
          //   key="dowloadLada"
          //   name={"Descargar"}
          //   //click={handlerClick}
          //  />,
          validatePermission('ruteo', 'ladas', ['agregar']) &&
          <CustomButton
            icon={<AddOutlined  />}
            key="addUser"
            name={"Crear Lada"}
            click={handlerClick}
          />
        ]}
        dataTitle={[
          "Lada",
          "Región",
          "Estado",
          "Fecha de Creación"
        ]}
        formatter={({ createdAt, lada, region, state }) => [
          lada,
          region.nombre,
          { name: "state", type: "boolean", value: state, disabled: !validatePermission('ruteo', 'ladas', ['editar']) },
          formatDateForTable(createdAt)
        ]}
        onChecked={handlerCheck}
        actions={[
          validatePermission('ruteo', 'ladas', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('ruteo', 'ladas', ['eliminar']) &&
          {
            label: "Eliminar",
            icon: <DeleteOutline />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  )
}

export default LadasScreen