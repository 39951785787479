import React from 'react';
import './BackButton.css';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';

const BackButton = ({ name, click }) => {
  return (
    <div className='container-wrap-back_button' onClick={click}>
      <div className='container-back_button'>
        <WestOutlinedIcon fontSize='small' color='primary' />
        <span className='back_button-name'>Regresar</span>
      </div>
    </div>
  )
}

export default BackButton;