import { AddOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, CustomButton, TableData } from "../../../../components";


import { confirmAlert, errorAlert } from "../../../../helpers/alerts";
import { Grid } from "@mui/material";
import { removePDSProduct, setCurrentPDSProduct, setPDSProducts, updatePDSProduct } from "redux/slices/admin/productSlice";
import { getPDSProductsService, removePDSProductService, updatePDSProductService } from "services/admin/productService";

const ProductsPaymentServiceScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pdsProducts, currentPDSSegment } = useSelector((state) => state.products);

  useEffect(() => {
    getPDSProductsService(currentPDSSegment.id)
      .then(data => dispatch(setPDSProducts(data)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerEdit = (row) => {
    dispatch(setCurrentPDSProduct(row));
    navigate("editar");
  };

  const handlerCheck = async ({ id }, currentValue, col) => {
    const value = !currentValue;
    updatePDSProductService(currentPDSSegment.id, id, { [col]: value })
      .then(() => dispatch(updatePDSProduct({ id, data: { [col]: value } })))
      .catch(error => errorAlert(error))

  };

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar este segmento?",
      text: `El segmento ${name} será eliminado.`,
      onConfirm: async () => {
        try {
          await removePDSProductService(currentPDSSegment.id, id,);
          dispatch(removePDSProduct(id));
        }
        catch (error) {
          errorAlert(error)
        }
      }
    })
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton
            name={"Volver"}
            click={() => navigate(-1)}
          />
        </div>
        <div className="container-title">
          <div className="title">Pago de Servicios</div>
          <div className="subtitle">{currentPDSSegment?.name}</div>
        </div>
        <div>
          <CustomButton
            icon={<AddOutlined  />}
            additional_class={"headerButton"}
            key="add"
            name={"Servicio"}
            click={() => navigate("crear")}
          />
        </div>
      </Grid>
      <TableData
        dataRows={pdsProducts}
        dataTitle={["Logo", "Nombre", "Código", "Estado"]}
        formatter={({ name, code, state, fd }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          code,
          { name: "state", type: "boolean", value: state },
        ]}
        onChecked={handlerCheck}
        actions={[
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          {
            label: "Eliminar",
            icon: <DeleteOutline />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  );
};

export default ProductsPaymentServiceScreen;
