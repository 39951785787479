import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import menu from "../../helpers/menu";
import { setSession } from "../../redux/slices/sessionSlice";
import { getUserPermissionsService } from "../../services/security/userServices";
import MenuBar from "./MenuBar";
import "./MenuPanel.css";
import { Skeleton } from "@mui/material";
import MenuHeader from "./MenuHeader";
import { useLocation, useNavigate } from "react-router-dom";

const MenuPanel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, sale, permissions } = useSelector((store) => store.session);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(setSession({ menu: menuJson }));
  // }, []);

  useEffect(() => {
    if (location.search === "?session_expired") return;
    if (id)
      (async () => {
        if (permissions.length === 0)
          setLoading(true);
        let list = await getUserPermissionsService(id);
        if (!sale)
          list = list.filter(
            ({ category, action }) =>
              !(category === "Venta" && action === "Ver")
          );
        dispatch(setSession({ permissions: list }));
        const panelPermission = list.find(
          ({ category, action }) =>
            category === "Panel de Control" && action === "Ver"
        );
        if (location.pathname === "/" && panelPermission)
          navigate("/panel/transacciones");
        setLoading(false);
      })();
    // eslint-disable-next-line
  }, [id]);

  const validateMenu = (keys) =>
    keys.some((key) =>
      permissions.filter(({ action }) => action === "Ver").map(({ category }) => category)?.includes(key) ||
      permissions.filter(({ action }) => action === "Ver").map(({ name }) => name)?.includes(key)
    );

  return (
    <div className="container-menu-panel">
      <MenuHeader />
      <div className="menu-body">
        {loading ? (
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            {[0, 0, 0, 0].map((i, j) => (
              <div key={j}>
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: 10 }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: 10, marginLeft: 30 }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: 10, marginLeft: 30 }}
                />
                <Skeleton
                  variant="rectangular"
                  height={40}
                  style={{ marginBottom: 10, marginLeft: 30 }}
                />
              </div>
            ))}
          </div>
        ) : (
          menu.map((item, i) => {
            return !validateMenu(item.keys) ? null : (
              <div key={i}>
                <MenuBar {...item}>
                  {item.list && (
                    <div className="list-menu-bar">
                      {item.list.map((item, i) =>
                        !validateMenu(item.keys) ? null : (
                          <MenuBar key={i} {...item}>
                            {item.list && (
                              <div className="sublist-menu-bar">
                                {item.list.map((item, i) =>
                                  !validateMenu(item.keys) ? null : (
                                    <MenuBar key={i} {...item} />
                                  )
                                )}
                              </div>
                            )}
                          </MenuBar>
                        )
                      )}
                    </div>
                  )}
                </MenuBar>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default MenuPanel;
