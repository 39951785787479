import { EditOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBox } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { validatePermission } from "../../../../../helpers/authentication";
import { setCurrentPaymentServiceProduct, setCurrentPaymentServiceSegment, setPaymentServiceProducts, setPaymentServiceSegments } from "redux/slices/admin/providerSlice";
import { getCredPaymentServiceProductsService, getCredPaymentServiceSegmentsService } from "services/admin/providerService";

const CredPDSScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    providerCredSelected,
    paymentServiceSegments,
    paymentServiceProducts,
    currentPaymentServiceSegment,
    currentPaymentServiceProduct
  } = useSelector((store) => store.providers);

  useEffect(() => {
    getCredPaymentServiceSegmentsService(providerCredSelected.id)
      .then(list => {
        dispatch(setPaymentServiceSegments(list));
      }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPaymentServiceSegment)
      getCredPaymentServiceProductsService(providerCredSelected.id, currentPaymentServiceSegment.id)
        .then(list => {
          dispatch(setPaymentServiceProducts(list));
        }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [providerCredSelected, currentPaymentServiceSegment]);


  const handlerClickSegment = (segment) => {
    if (segment.id !== currentPaymentServiceSegment?.id) {
      batch(() => {
        dispatch(setCurrentPaymentServiceSegment(segment));
        dispatch(setCurrentPaymentServiceProduct());
      })
    }
  };

  const handlerClickProduct = (product) => {
    if (product.id !== currentPaymentServiceProduct?.id) {
      dispatch(setCurrentPaymentServiceProduct(product));
    }
  };

  return (
    <div>
      <CardBox
        name={"Segmentos"}
        values={paymentServiceSegments}
        handleSelect={handlerClickSegment}
        isSelect={currentPaymentServiceSegment?.id}
        buttons={[
          validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
          {
            icon: <EditOutlined  />,
            key: "segments",
            name: "Administrar",
            click: () => navigate("segmentos"),
          },
        ]}
        formatter={({ idSegment: item }) => ({
          ...item,
          image: `/v3/file/${item?.fd}/`,
        })}
      />
      {
        currentPaymentServiceSegment && (
          <CardBox
            name={"Servicios"}
            values={paymentServiceProducts?.filter(({ state }) => state)}
            handleSelect={handlerClickProduct}
            isSelect={currentPaymentServiceSegment?.id}
            buttons={[
              validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              {
                icon: <EditOutlined  />,
                key: "products",
                name: "Administrar",
                click: () => navigate("productos"),
              },
            ]}
            formatter={({ idProduct: item }) => ({
              ...item,
              image: `/v3/file/${item?.fd}/`,
            })}

          />
        )
      }
    </div >
  );
};

export default CredPDSScreen;
