import React, { useEffect } from 'react';

import { AddCircleOutline, AddOutlined, Close } from '@mui/icons-material';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { CustomButton, TableData } from 'components';
import { validatePermission } from 'helpers/authentication';

import { errorAlert } from 'helpers/alerts';
import { getCarriersListService } from 'services/admin/providerService';
import { setCarrierList } from 'redux/slices/admin/providerSlice';

function CarriersScreen() {
    const dispatch = useDispatch();
    const { carrierList } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Carriers'));
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        (async () => {
            try {
                console.log("getCarriersListService");
                const response = await getCarriersListService();
                console.log("getCarriersListService",response);
                dispatch(setCarrierList(response));
            } catch (error) {
                errorAlert(error);
            }
        })();
    }, []);

    return (
    <TableData
        dataRows={carrierList}
        headerButtons={[
        validatePermission('proveedores', 'proveedores', ['agregar']) &&
        <CustomButton
            icon={<AddOutlined />}
            key="addCarriers"
            name={"Nuevo carrier"}
            click={() => {}}
        />,
        ]}
        dataTitle={[
            "Nombre",
            "Carrier Id"
        ]}
        formatter={({ name, carrier_id }) => ([
            name,
            carrier_id
        ])}
        actions={[
            validatePermission('proveedores', 'productos', ['editar']) &&
            {
                label: "Eliminar",
                icon: <Close />,
                onClick: (data) => {
                
                },
            },
        ]}
    />
    );
}

export default CarriersScreen;