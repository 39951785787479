import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from '../../../components';
import { setCurrentRole, setRoles, updateRole, removeRole } from '../../../redux/slices/security/roleSlice';
import { getRolesService, removeRoleService, updateRoleService } from '../../../services/security/roleService';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { validatePermission } from '../../../helpers/authentication';

const RoleScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rol } = useSelector((store) => store.session);
  const { roles } = useSelector(store => store.roles)
  const rolId = rol?.id;

  useEffect(() => {
    (async () => {
      try {
        const roles = await getRolesService(rolId);
        dispatch(setRoles(roles));
      } catch (error) {
        errorAlert(error)
      }
    })()
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => {
    navigate('crear')
  };

  const handlerCheck = async (row, currentValue) => {
    try {
      const value = !currentValue;
      await updateRoleService(row.id, value);
      dispatch(updateRole({ id: row.id, data: { state: value } }))
    } catch (error) {
      errorAlert(error)
    }

  };

  const handlerEdit = (row) => {
    dispatch(setCurrentRole(row));
    navigate("editar")
  };

  const handlerRemove = (row) => {
    confirmAlert({
      title: 'Estas seguro que deseas eliminar este rol?',
      text: `El rol '${row.name}' será eliminado.`,
      onConfirm: async () => {
        try {
          await removeRoleService(row.id);
          dispatch(removeRole(row.id));
        } catch (error) {
          errorAlert(error);
        }
      },
    });
  };

  return (
    <TableData
      dataRows={roles}
      headerButtons={[
        validatePermission('roles', 'roles', ['agregar']) &&
        <CustomButton
          icon={<AddOutlined  />}
          key="addRole"
          name={"Crear rol"}
          click={handlerClick}
        />,
      ]}
      dataTitle={[
        "Nombre",
        "Tipo",
        // "Estado",
      ]}
      formatter={({ name, type, state }) => ([
        name,
        type,
        // { type: 'boolean', value: state },
      ])}
      onChecked={handlerCheck}
      actions={[
        validatePermission('roles', 'roles', ['editar']) &&
        {
          label: "Editar",
          icon: <EditOutlined />,
          onClick: (data) => {
            handlerEdit(data);
          },
        },
        validatePermission('roles', 'roles', ['eliminar']) &&
        {
          label: "Eliminar",
          icon: <DeleteOutline />,
          onClick: (data) => {
            handlerRemove(data);
          }
        }
      ]}
    />
  )
}

export default RoleScreen