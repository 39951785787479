import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BackButton, CustomButton, TableData } from '../../../../components';
import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';


import { Grid } from '@mui/material';
import { getExtraDenominationByDenominationService, removeExtraDenominationService, updateExtraDenominationService } from '../../../../services/admin/extraDenominationService';
import { setExtraDenominations, removeExtraDenomination, setCurrentExtraDenomination, updateExtraDenomination } from '../../../../redux/slices/admin/extraDenominationSlice';
import { confirmAlert, errorAlert } from '../../../../helpers/alerts';
import { validatePermission } from '../../../../helpers/authentication';

const ExtraDenominationScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentOperator } = useSelector((store) => store.operators);
  const { currentSegment } = useSelector((store) => store.segments);
  const { currentDenomination } = useSelector((store) => store.denominations);
  const { extraDenominations } = useSelector((store) => store.extraDenominations);

  useEffect(() => {
    (async () => {
      try {
        const extraDenomination = await getExtraDenominationByDenominationService(currentDenomination.id);
        dispatch(setExtraDenominations(extraDenomination));
      } catch (error) {
        errorAlert(error)
      }
    })()
    // eslint-disable-next-line
  }, []);

  const handlerBack = () => navigate(-1);

  const handlerAdd = () =>  navigate("crear");

  const handlerEdit = (row) => {
    dispatch(setCurrentExtraDenomination(row));
    navigate("editar");
  };

  const handlerDelete = ({ id, nombre }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar esta denominación extra?",
      text: `La denominación extra ${nombre} será eliminada.`,
      onConfirm: async () => {
        try {
          await removeExtraDenominationService(id);
          dispatch(removeExtraDenomination(id));
        }
        catch (error) {
          errorAlert(error);
        }
      }
    })
  };
  const handlerCheck = async ({ id }, currentValue) => {
    const value = !currentValue;
    await updateExtraDenominationService(id, { state: value, image: true });
    dispatch(updateExtraDenomination({ id, data: { state: value } }));
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" >
        <div>
          <BackButton name={"Denominaciones extras"} click={handlerBack} />
        </div>
        <div className='container-title'>
          <div className='title'>Operadora: {currentOperator.name} </div>
          <div className='subtitle'>Segmento: {currentSegment.name} </div>
          <div className='subtitle2'>Denominación: {currentDenomination.denomination} </div>
        </div>
        <div style={{ width: '170px' }}>
        </div>
      </Grid>
      <TableData
        dataRows={extraDenominations}
        headerButtons={[
          validatePermission('productos', 'productos', ['editar']) &&
          <CustomButton
            icon={<AddOutlined  />}
            key="addExtraDenomination"
            name={"Extra"}
            click={handlerAdd}
          />,
        ]}
        dataTitle={["Logo", "Nombre", "Vigencia", "Descripción", "GB", "Datos", "Estado"]}
        formatter={({ fd, nombre, vig, description, gb, datos, state }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          nombre,
          vig,
          description,
          gb,
          datos,
          { type: "boolean", value: state, disabled: !validatePermission('productos', 'productos', ['editar']) },
        ]}
        onChecked={handlerCheck}
        actions={[
          validatePermission('productos', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('productos', 'productos', ['eliminar']) &&
          {
            label: "Eliminar",
            icon: <DeleteOutline />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  )
}

export default ExtraDenominationScreen