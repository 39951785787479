import { EditOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBox } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { validatePermission } from "../../../../../helpers/authentication";
import { setCurrentOperator, setCurrentSegment, setDenominations, setOperators, setSegments } from "../../../../../redux/slices/admin/providerSlice";
import { getCredTAEOperatorsService, getCredTAEProductsService, getCredTAESegmentsService } from "../../../../../services/admin/providerService";

const CredTAEScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    providerCredSelected,
    operators,
    segments,
    denominations,
    currentOperator,
    currentSegment
  } = useSelector((store) => store.providers);

  useEffect(() => {
    getCredTAEOperatorsService(providerCredSelected.id)
      .then(list => dispatch(setOperators(list
        .sort((
          { idOperator: { createdAt: c1 } },
          { idOperator: { createdAt: c2 } }
        ) => (c1 - c2))
      )))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [providerCredSelected]);

  useEffect(() => {
    if (currentOperator)
      getCredTAESegmentsService(providerCredSelected.id, currentOperator.id)
        .then(list => dispatch(setSegments(list
          .sort((
            { idSegment: { carrierId: c1 } },
            { idSegment: { carrierId: c2 } }
          ) => (c1 - c2))
        )))
        .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [providerCredSelected, currentOperator]);

  useEffect(() => {
    if (currentSegment)
      getCredTAEProductsService(providerCredSelected.id, currentOperator.id, currentSegment.id)
        .then(result => dispatch(setDenominations(result)))
        .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [currentSegment]);

  const handlerClickOperator = (operator) => {
    if (operator.id !== currentOperator?.id) {
      batch(() => {
        dispatch(setCurrentOperator(operator));
        dispatch(setCurrentSegment());
      })
    }
  };

  const handlerClickSegment = (segment) => {
    if (segment.id !== currentSegment?.id)
      dispatch(setCurrentSegment(segment));
  };

  return (
    <div>
      <CardBox
        name={"Operadoras"}
        values={operators}
        handleSelect={handlerClickOperator}
        isSelect={currentOperator?.id}
        buttons={[
          validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
          {
            icon: <EditOutlined  />,
            key: "operators",
            name: "Administrar",
            click: () => navigate("operadoras"),
          },
        ]}
        formatter={({ idOperator: item }) => ({
          ...item,
          image: `/v3/file/${item.fd}/`,
        })}
      />
      {
        currentOperator && (
          <CardBox
            name={"Segmentos"}
            values={segments}
            handleSelect={handlerClickSegment}
            isSelect={currentSegment?.id}
            buttons={[
              validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              {
                icon: <EditOutlined  />,
                key: "segments",
                name: "Administrar",
                click: () => navigate("segmentos"),
              },
            ]}
            formatter={({ idSegment: item }) => ({
              ...item,
              image: `/v3/file/${item.fd}/`,
            })}
          />
        )
      }
      {
        currentSegment && (
          <CardBox
            name={"Denominaciones"}
            values={denominations}
            formatter={({ idProduct: item, sku }) => ({
              ...item,
              name: item.denomination,
              sku,
            })}
            buttons={[
              validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              {
                icon: <EditOutlined  />,
                key: "denominations",
                name: "Administrar",
                click: () => navigate("productos"),
              },
            ]}
            element={({ sku }) => sku && <div className="sku">SKU: {sku} </div>}
          />
        )
      }
    </div >
  );
};

export default CredTAEScreen;
