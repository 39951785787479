import { EditOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBox } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { validatePermission } from "../../../../../helpers/authentication";
import { setCurrentPINProduct, setCurrentPINSegment, setPINProducts, setPINSegments } from "redux/slices/admin/providerSlice";
import { getCredPINProductsService, getCredPINSegmentsService } from "services/admin/providerService";

const CredPINScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    providerCredSelected,
    pinSegments,
    pinProducts,
    currentPINSegment,
    currentPINProduct
  } = useSelector((store) => store.providers);

  useEffect(() => {
    getCredPINSegmentsService(providerCredSelected.id)
      .then(list => {
        dispatch(setPINSegments(list));
      }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPINSegment)
      getCredPINProductsService(providerCredSelected.id, currentPINSegment.id)
        .then(list => {
          dispatch(setPINProducts(list));
        }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [providerCredSelected, currentPINSegment]);


  const handlerClickSegment = (segment) => {
    if (segment.id !== currentPINSegment?.id) {
      batch(() => {
        dispatch(setCurrentPINSegment(segment));
        dispatch(setCurrentPINProduct());
      })
    }
  };

  const handlerClickProduct = (product) => {
    if (product.id !== currentPINProduct?.id) {
      dispatch(setCurrentPINProduct(product));
    }
  };

  return (
    <div>
      <CardBox
        name={"Segmentos"}
        values={pinSegments}
        handleSelect={handlerClickSegment}
        isSelect={currentPINSegment?.id}
        buttons={[
          validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
          {
            icon: <EditOutlined  />,
            key: "segments",
            name: "Administrar",
            click: () => navigate("segmentos"),
          },
        ]}
        formatter={({ idSegment: item }) => ({
          ...item,
          image: `/v3/file/${item.fd}/`,
        })}
      />
      {
        currentPINSegment && (
          <CardBox
            name={"Servicios"}
            values={pinProducts?.filter(({ state }) => state)}
            handleSelect={handlerClickProduct}
            isSelect={currentPINSegment?.id}
            buttons={[
              validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              {
                icon: <EditOutlined  />,
                key: "products",
                name: "Administrar",
                click: () => navigate("productos"),
              },
            ]}
            formatter={({ idProduct: item }) => ({
              ...item,
              image: `/v3/file/${item?.fd}/`,
            })}

          />
        )
      }
    </div >
  );
};

export default CredPINScreen;
