import { AddOutlined, EditOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from '../../../components'
import { errorAlert } from '../../../helpers/alerts';
import { setConnectors, setcurrentConnector, updateConnector } from '../../../redux/slices/admin/connectorSlice';
import { getConnectorsService, updateConnectorService } from '../../../services/admin/connectorService';

import { validatePermission } from '../../../helpers/authentication';

const ConnectorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connectors } = useSelector(store => store.connectors);

  useEffect(() => {
    (async () => {
      try {
        const result = await getConnectorsService();
        dispatch(setConnectors(result))
      } catch (error) {
        errorAlert(error)
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => {
    navigate('crear')
  }

  const handlerEdit = (row) => {
    dispatch(setcurrentConnector(row));
    navigate("editar");
  };

  const handlerCheck = async ({ id }, currentValue) => {
    const value = !currentValue;
    try {
      await updateConnectorService(id, { active_system: value });
      dispatch(updateConnector({ id, data: { active_system: value } }));
    }
    catch (error) {
      errorAlert(error)
    }

  };

  return (
    <TableData
      dataRows={connectors}
      headerButtons={[
        validatePermission('conectores', 'conectores', ['agregar']) &&
        <CustomButton
          icon={<AddOutlined  />}
          key="addUser"
          name={"Conector"}
          click={handlerClick}
        />,
      ]}
      dataTitle={[
        "Nombre",
        "Id",
        "Descripción",
        "Estado",
      ]}
      formatter={({ container_name, id, description, active_system }) => [
        container_name,
        id,
        description,
        { name: "active_system", type: "boolean", value: active_system, disabled: !validatePermission('conectores', 'conectores', ['editar']) },

      ]}
      onChecked={handlerCheck}
      actions={[
        validatePermission('conectores', 'conectores', ['editar']) &&
        {
          label: "Editar",
          icon: <EditOutlined />,
          onClick: (data) => {
            handlerEdit(data);
          },
        },
      ]}
    />

  )
}

export default ConnectorScreen