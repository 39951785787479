import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from '../../../components'
import { errorAlert } from '../../../helpers/alerts';
import { getAlertsService, updateAlertService } from 'services/admin/alertsService';
import { setAlerts, setCurrentAlert, updateAlert } from 'redux/slices/admin/alertSlice';
import moment from 'moment';
import { EditOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SystemAlertsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alerts } = useSelector(store => store.alert);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    getAlertsService("sistema").then((result) => {
      dispatch(setAlerts(result))
    }).catch(error => errorAlert(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handlerEdit = (row) => {
    dispatch(setCurrentAlert(row));
    navigate("editar");
  };

  const handlerCheck = async ({ id }, currentValue) => {
    const value = !currentValue;
    updateAlertService(id, { state: value }).then(({ state, updatedAt }) => {
      dispatch(updateAlert({ id, data: { state, updatedAt } }));
    }).catch(error => errorAlert(error))
  };

  return (
    <TableData
      dataRows={alerts}
      loading={loading}
      dataTitle={[
        "Nombre",
        "Emails",
        "Estado",
        "Activacion/Desactivacion"
      ]}
      formatter={({ name, emails, state, updatedAt }) => [
        name,
        emails.join("\n\r"),
        { name: "state", type: "boolean", value: state },
        moment(updatedAt).format('DD/MM/YYYY HH:mm:ss '),
      ]}
      onChecked={handlerCheck}
      actions={[
        {
          label: "Editar",
          icon: <EditOutlined />,
          onClick: (data) => handlerEdit(data)
        },
      ]}
    />

  )
}

export default SystemAlertsScreen