import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Add, AddCircleOutline, AddOutlined, DeleteOutlined, EditOutlined, Filter1Outlined, FilterOutlined, Inventory2Outlined, Password, PasswordOutlined, PendingOutlined, SettingsOutlined } from '@mui/icons-material';


import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { CustomButton, FilterBar, TableData } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { getCommerceTypeService, getCommercesService, removeCommerceService, updateCommerceStateService } from '../../../services/client/commerceService';
import { removeCommerce, setCommerceTypes, setCommerces, setCurrentCommerce, updateCommerce } from '../../../redux/slices/client/commerceSlice';
import { validatePermission } from '../../../helpers/authentication';
import { setBagFilters, setCurrentBag } from '../../../redux/slices/client/bagSlice';
import { setSalePointFilters } from '../../../redux/slices/client/salePointSlice';
import { recoverPassword, resetPassword } from 'services/security/userServices';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InventoryIcon from '@mui/icons-material/Inventory';
import { currencyFormatter } from 'helpers/util';
import ColumnFilterModal from 'components/modal/ColumnFilterModal';
import { getGroupsService } from 'services/client/groupService';
import { setGroups } from 'redux/slices/client/groupSlice';



const CommerceScreen = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hoster, rol } = useSelector(store => store.session);
  const { groups } = useSelector(store => store.group);
  const { commerces, commerceTypes } = useSelector(store => store.commerces);
  const [showColumnFilterModal, setShowColumnFilterModal] = useState(false)
  const [selectedColums, setSetSelectedColums] = useState([
    "id",
    "name",
    "type",
    "earnings",
    "earnings_scheme",
    "balanceTAE",
    "balancePDS",
    "salepoints",
    "user",
    "state",
  ])
  const columns = [
    { name: "id", label: "Id", format: ({ identification }) => identification },
    { name: "name", label: "Nombre", format: ({ username }) => username },
    { name: "type", label: "Tipo", format: ({ type_commerce }) => type_commerce },
    { name: "earnings", label: "Comisión (TAE)", format: ({ bags }) => bags?.find(bag => bag.type === 'TAE')?.earnings },
    { name: "earnings_scheme", label: "Ganacia (TAE)", format: ({ bags }) => bags?.find(bag => bag.type === 'TAE')?.earnings_scheme },
    {
      name: "balanceTAE", label: "Saldo (TAE)", format: ({ bags }) => {
        const saldo = bags?.find(bag => bag.type === 'TAE')?.saldo
        return saldo ? currencyFormatter.format(saldo) : ''
      }
    },
    {
      name: "balancePDS", label: "Saldo (PDS)", format: ({ bags }) => {
        const saldo = bags?.find(bag => bag.type === 'PDS')?.saldo
        return saldo ? currencyFormatter.format(saldo) : ''
      }
    },
    { name: "bags", label: "Bolsas", format: ({ type_commerce, bags }) => type_commerce !== 'H2H' ? '' : { name: "bags", type: "button", value: bags, action: handleBags } },
    { name: "salepoints", label: "Puntos de Venta", format: ({ salePoints }) => ({ name: "salePoints", type: "button", value: salePoints, action: handleSalePoints }) },
    { name: "user", label: "Usuario", format: ({ user }) => user?.email },
    { name: "state", label: "Estado", format: ({ _id, hoster, state }) => ({ name: "state", type: "boolean", value: state, disabled: _id === hoster }) },
  ]

  useEffect(() => {
    dispatch(setCurrentCommerce(null));
    getGroupsService()
      .then((response) => dispatch(setGroups(response)))
      .catch((e) => errorAlert(e))
    getCommerceTypeService(rol.name)
      .then((response) => dispatch(setCommerceTypes(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);


  const handlerSelectColumn = (columns) => {
    setSetSelectedColums(columns)
    setShowColumnFilterModal(false)
  }

  const handlerClick = () => {
    navigate('crear')
  }

  const handlerAddBag = (row) => {
    dispatch(setCurrentCommerce(row));
    navigate('/cliente/bolsas/crear')
  }

  const handlerEdit = (row) => {
    dispatch(setCurrentCommerce({
      ...row,
      type_bag: row.bags.map(bag => bag.type)
    }));
    navigate("editar");
  };

  const handlerCheck = (row, currentValue) => {
    const value = !currentValue;
    updateCommerceStateService(row._id, { state: value })
      .then(() => dispatch(updateCommerce({ id: row._id, data: { state: value } })))
      .catch((e) => errorAlert(e))
  };

  const handlerDelete = (row) => {
    confirmAlert({
      title: "Estás seguro que deseas eliminar este comercio?",
      text: `El comercio: ${row.username} será eliminado.`,
      onConfirm: () => {
        removeCommerceService(row._id)
          .then(() => dispatch(removeCommerce(row._id)))
          .catch((e) => errorAlert(e))
      }
    })
  };

  const handlerSubmit = (filters) => {
    getCommercesService(filters.type, filters.state, filters.group)
      .then((result) => dispatch(setCommerces(result.map(item => ({
        ...item,
        bag: {
          ...item.bag,
          hoster: {
            id: item._id,
            username: item.username,
          }
        }
      })))))
      .catch((e) => errorAlert(e))
  }

  const handleBags = (row) => {
    dispatch(setBagFilters({ commerce: row._id }))
    navigate("/cliente/bolsas")
  }

  const handleSalePoints = (row) => {
    dispatch(setSalePointFilters({ commerce: row._id }))
    navigate("/cliente/puntos_de_venta")
  }

  const handlerOperations = (row) => {
    dispatch(setCurrentCommerce(row));
    navigate("/cliente/bolsas/operaciones");
  };

  const handlerProducts = (row) => {
    dispatch(setCurrentCommerce(row));
    navigate("/cliente/bolsas/productos");
  };

  const handlerAddSalePoint = (row) => {
    dispatch(setCurrentCommerce(row));
    navigate('/cliente/puntos_de_venta/crear')
  }

  const handlerResetPassword = ({ user: { id, email }, type_commerce }) => {
    const sendEmail = type_commerce !== 'H2H';
    confirmAlert({
      title: `¿Estas seguro que deseas restablecer la contraseña del usuario ${email}?`,
      text: sendEmail ?
        `Se enviará al usuario un correo electrónico con un enlace para restablecer` :
        `La contraseña se actulizará y mostrará la nueva contraseña solo una vez.`,
      onConfirm: () => {
        if (sendEmail)
          recoverPassword(email).then(() => {
            confirmAlert({
              icon: 'success',
              title: `¡Correo enviado exitosamente!`,
              showCancelButton: false,
            });
          }).catch((e) => errorAlert(e))
        else
          resetPassword(id).then(({ password }) => {
            confirmAlert({
              icon: 'success',
              title: `¡Se restablecio la contraseña exitosamente!`,
              html: `<b>Contraseña: </b> ${password}`,
              showCancelButton: false,
            });
          }).catch((e) => errorAlert(e))
      },
    });
  }
  console.log('hoster', hoster);

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        defaultValues={{
          state: true,
        }}
        filters={[
          {
            name: 'group',
            label: "Grupo",
            type: "select",
            all: 'Todos',
            data: groups,
          },
          {
            name: 'type',
            label: "Tipo",
            type: "select",
            all: 'Todos',
            data: commerceTypes,
          },
          {
            name: 'state',
            label: "Estado",
            type: "select",
            data: [
              { name: "Todos" },
              { id: true, name: "Activos" },
              { id: false, name: "Inactivos" },
            ],
          },
        ]}
      />
      <TableData
        dataRows={commerces}
        headerButtons={[
          validatePermission('comercios', 'comercios', ['agregar']) &&
          <CustomButton
            icon={<AddOutlined />}
            key="addCommerce"
            name={"Comercio"}
            click={handlerClick}
          />,
          <CustomButton
            icon={<Filter1Outlined />}
            key="filterColumns"
            name={"Columnas"}
            additional_class={"columnFilterButton"}
            click={() => setShowColumnFilterModal(true)}
          />,
        ]}
        dataTitle={columns.filter(({ name }) => selectedColums.includes(name)).map(({ label }) => label)}
        formatter={(data) => columns.filter(({ name }) => selectedColums.includes(name)).map(({ format }) => format(data))}
        onChecked={handlerCheck}
        actions={[
          validatePermission('comercios', 'comercios', ['editar']) &&
          // {
          //   label: "Agregar bolsa",
          //   icon: <Add  />,
          //   validate: (data) => data.type_commerce === 'H2H',
          //   onClick: (data) => handlerAddBag(data)
          // },
          {
            label: "Agregar punto de venta",
            icon: <AddOutlined />,
            onClick: (data) => handlerAddSalePoint(data),
            validate: (data) => ['DISTRIBUIDOR', 'MENUDEO'].includes(data.type_commerce) && (hoster == undefined || data._id !== hoster),
          },
          validatePermission('comercios', 'comercios', ['editar']) &&
          {
            label: "Operaciones",
            icon: <SettingsOutlined />,
            onClick: (data) => handlerOperations(data),
            validate: (data) => ['DISTRIBUIDOR', 'MENUDEO', 'H2H'].includes(data.type_commerce) && (hoster == undefined || data._id !== hoster),

          },
          validatePermission('comercios', 'comercios', ['editar']) &&
          {
            label: "Productos",
            icon: <Inventory2Outlined />,
            onClick: (data) => handlerProducts(data),
            validate: (data) => ['DISTRIBUIDOR', 'MENUDEO', 'H2H'].includes(data.type_commerce),

          },
          validatePermission('comercios', 'comercios', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => handlerEdit(data),
            validate: (data) => data._id !== hoster,
          },
          validatePermission('comercios', 'comercios', ['editar']) &&
          {
            label: "Restablecer Contraseña",
            icon: <PasswordOutlined />,
            onClick: (data) => handlerResetPassword(data),
            validate: (data) => data._id !== hoster,
          },
          {
            label: "Eliminar",
            icon: <DeleteOutlined />,
            onClick: (data) => handlerDelete(data),
            validate: (data) => data._id !== hoster,
          },
        ]}
      />
      <ColumnFilterModal
        open={showColumnFilterModal}
        columns={columns}
        selected={selectedColums}
        onSuccess={handlerSelectColumn}
        onClose={() => setShowColumnFilterModal(false)}
      />
    </>
  )
}

export default CommerceScreen