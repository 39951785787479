import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PanelTab from './PanelTab';
import './CustomTab.css'
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { primaryColor } from '../../config/theme';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTab = ({ title, base, panels }) => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  panels = panels.filter(({ show = true }) => show);

  useEffect(() => {
    if (location.pathname === base) {
      if (panels[value] && typeof panels[value].onChange === 'function')
        setTimeout(() => {
          panels[value].onChange()
        }, 0);
    } else {
      let index = panels.findIndex(({ route }) => location.pathname == route)
      index = index === -1 ? panels.findIndex(({ route }) => location.pathname.includes(route)) : index
      setValue(index === -1 ? 0 : index)
    }
    // eslint-disable-next-line
  }, [location])



  const handlerChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      {
        title && (
          <Typography variant="h5" gutterBottom align='center' sx={{ color: primaryColor, marginTop: 2, fontWeight: 'bold' }}>
            {title}
          </Typography>
        )
      }
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value || 0}
          variant="fullWidth"
          onChange={handlerChange}
          aria-label="basic tabs example">
          {
            panels.map(({ name, onChange }, i) => {
              return <Tab key={`tab_${i}`} label={name} onClick={onChange}
                {...a11yProps(i)}
              />
            })
          }
        </Tabs>
      </Box>
      {
        panels.map(({ content }, i) => {
          return <PanelTab
            key={`panelTab_${i}`}
            value={value || 0}
            index={i}
          >
            {content}
          </PanelTab>
        })
      }
    </Box>
  );
}

export default CustomTab