import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from '../../../components';
import { removePermission, setCurrentPermission, updatePermission, setPermissions } from '../../../redux/slices/security/permissionSlice';
import { getPermissionsService, updatePermissionService, removePermissionService } from '../../../services/security/permissionService';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { validatePermission } from '../../../helpers/authentication';

const PermissionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions } = useSelector(store => store.permissions)

  useEffect(() => {
    (async () => {
      try {
        let response = await getPermissionsService();
        response.sort((a, b) => Number(b.state) - Number(a.state))
        dispatch(setPermissions(response));
      } catch (error) {
        errorAlert(error)
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => {
    navigate('crear')
  }

  const handlerEdit = async (row) => {
    dispatch(setCurrentPermission(row));
    navigate("editar")
  };

  const handlerCheck = async (row, currentValue) => {
    try {
      const value = !currentValue;
      await updatePermissionService(row.id, { ...row, state: value });
      dispatch(updatePermission({ id: row.id, data: { state: value } }))
    } catch (error) {
      errorAlert(error);
    }
  }

  const handlerDelete = (row) => {
    confirmAlert({
      title: 'Estas seguro que deseas eliminar este permiso?',
      text: `El permiso '${row.name}' será eliminado.`,
      onConfirm: async () => {
        try {
          await removePermissionService(row.id);
          dispatch(removePermission(row.id));
        } catch (error) {
          errorAlert(error);
        }
      },
    });
  };

  return (
    <TableData
      dataRows={permissions}
      headerButtons={[
        validatePermission('permisos', 'permisos', ['agregar']) &&
        <CustomButton
          icon={<AddOutlined  />}
          key="addPermission"
          name={"Crear Permiso"}
          click={handlerClick}
        />,
      ]}
      dataTitle={[
        "Tipo",
        "Categoria",
        "Nombre",
        "Estado"
      ]}
      formatter={({ name, category, type, state }) => ([
        type,
        category,
        name,
        { type: 'boolean', value: state, disabled: !validatePermission('permisos', 'permisos', ['agregar']) },
      ])}
      onChecked={handlerCheck}
      actions={[
        validatePermission('permisos', 'permisos', ['editar']) &&
        {
          label: "Editar",
          icon: <EditOutlined />,
          onClick: (data) => {
            handlerEdit(data);
          },
        },
        validatePermission('permisos', 'permisos', ['eliminar']) &&
        {
          label: "Eliminar",
          icon: <DeleteOutline />,
          onClick: (data) => {
            handlerDelete(data);
          }
        }
      ]}
    />
  )
}

export default PermissionScreen