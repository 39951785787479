import { Add, AddOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import { BackButton, CustomButton, TableData } from "../../../../components";
import {
  removeDenomination,
  setCurrentDenomination,
  setDenominations,
} from "../../../../redux/slices/admin/denominationSlice";
import { confirmAlert, errorAlert } from "../../../../helpers/alerts";
import { removeDenominationService, updateDenominationService } from "../../../../services/admin/denominationServices";
import { Grid } from "@mui/material";
import { validatePermission } from "../../../../helpers/authentication";

const DenominationScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { denominations } = useSelector((store) => store.denominations);
  const { currentOperator } = useSelector((store) => store.operators);
  const { currentSegment } = useSelector((store) => store.segments);

  const handlerBack = () => navigate(-1);

  const handlerAdd = () => navigate("crear");

  const handlerEdit = (row) => {
    dispatch(setCurrentDenomination(row));
    navigate("editar");
  };

  const handlerExtra = (row) => {
    dispatch(setCurrentDenomination(row));
    navigate("extradenominaciones");
  }

  const handlerCheck = async (row, currentValue) => {
    const data = { ...row, state: !currentValue }
    try {
      const response = await updateDenominationService(row.id, data);
      dispatch(setDenominations(response));
    }
    catch (error) {
      errorAlert(error);
    }
  };

  const handlerDelete = ({ id, denomination }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar esta denominación?",
      text: `La denominación ${denomination} será eliminada.`,
      onConfirm: async () => {
        try {
          await removeDenominationService(id);
          dispatch(removeDenomination(id));
        }
        catch (error) {
          errorAlert(error);
        }
      }
    })
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" >
        <div>
          <BackButton name={"Denominaciones"} click={handlerBack} />
        </div>
        <div className='container-title'>
          <div className='title'>Operadora: {currentOperator.name} </div>
          <div className='subtitle'>Segmento: {currentSegment.name} </div>
        </div>
        <div style={{ width: '170px' }}>
        </div>
      </Grid>

      <TableData
        dataRows={denominations}
        headerButtons={[
          validatePermission('productos', 'productos', ['agregar']) &&
          <CustomButton
            icon={<AddOutlined />}
            key="addDenomination"
            name={"Denominación"}
            click={handlerAdd}
          />,
        ]}
        dataTitle={["Nombre", "Importe", "Vigencia", "Estado"]}
        formatter={({ denomination, imp, vig, state }) => [
          denomination,
          imp,
          vig,
          { type: "boolean", value: state, disabled: !validatePermission('productos', 'productos', ['editar']) },
        ]}
        onChecked={handlerCheck}
        actions={[
          validatePermission('productos', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('productos', 'productos', ['ver', 'agregar', 'editar', 'eliminar']) &&
          {
            label: "Extras",
            icon: <Add />,
            onClick: (data) => {
              handlerExtra(data);
            }
          },
          validatePermission('productos', 'productos', ['eliminar']) &&
          {
            label: "Eliminar",
            icon: <DeleteOutline />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  );
};

export default DenominationScreen;
