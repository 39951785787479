import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BackButton, TableData } from 'components';
import { getServiceMonitorHistorialService } from 'services/admin/serviceMonitorService';
import { errorAlert } from 'helpers/alerts';
import moment from 'moment';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ServiceMonitorHistorialScreen = () => {
  const navigate = useNavigate();
  const { currentMonitor } = useSelector(store => store.serviceMonitor);
  const [historial, setHistorial] = useState([])

  useEffect(() => {
    getServiceMonitorHistorialService(currentMonitor.code).then((response) => {
      setHistorial(response)
    }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton
            name={"Monitor"}
            click={() => navigate(-1)}
          />
        </div>
        <div className="container-title">
          <div className="title">Historial</div>
          <div className="subtitle">{currentMonitor.name}</div>

        </div>
        <div style={{ width: "170px" }}></div>
      </Grid>
      <TableData
        dataRows={historial.sort((a, b) => b.createdAt - a.createdAt)}
        dataTitle={[
          "Estado",
          "Mensaje",
          "Inicio",
          "Fin",
          "Tiempo"
        ]}
        formatter={({
          current,
          createdAt,
          state,
          message,
          updatedAt
        }) => [
            <div style={{
              height: 20,
              width: 20,
              borderRadius: 25,
              backgroundColor: state ? 'green' : 'red',
              margin: 'auto'
            }} />,
            message,
            moment(createdAt).format('DD/MM/YYYY HH:mm:ss '),
            current ? '-' : moment(updatedAt).format('DD/MM/YYYY HH:mm:ss '),
            <div>{moment(createdAt).from(moment(updatedAt), true)} {state ? 'en linea' : 'caído'}</div>,
          ]}
      />
    </>

  )
}

export default ServiceMonitorHistorialScreen