import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterBar, TableData } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { removeBag, setBagFilters, setBags, setCurrentBag, updateBag } from '../../../redux/slices/client/bagSlice';
import { getBagsService, removeBagService, updateBagService } from '../../../services/client/bagService';

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InventoryIcon from '@mui/icons-material/Inventory';

import { getCommercesService } from '../../../services/client/commerceService';
import { setCommerces } from '../../../redux/slices/client/commerceSlice';
import { setSalePointFilters } from '../../../redux/slices/client/salePointSlice';
import { Add, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { currencyFormatter } from '../../../helpers/util';
import { validatePermission } from '../../../helpers/authentication';

const BagScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bags, filters } = useSelector(store => store.bags);
  const { commerces } = useSelector((store) => store.commerces);

  useEffect(() => {
    getCommercesService(undefined, true)
      .then((response) => dispatch(setCommerces(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  const handlerFilter = (data) => {
    getBagsService(data)
      .then((response) => dispatch(setBags(response)))
      .catch((e) => errorAlert(e))
      .finally(() => dispatch(setBagFilters({})))
  }

  // const handlerClick = () => {
  //   navigate('crear')
  // }

  const handlerEdit = (data) => {
    dispatch(setCurrentBag(data));
    navigate("editar");
  };

  const handlerDelete = ({ _id, hashtag }) => {
    confirmAlert({
      title: "Estás seguro que deseas eliminar esta bolsa?",
      text: `La bolsa: ${hashtag} será eliminada.`,
      onConfirm: () => {
        removeBagService(_id)
          .then(() => dispatch(removeBag(_id)))
          .catch((e) => errorAlert(e))
      }
    })
  };

  const handlerOperations = (row) => {
    dispatch(setCurrentBag(row));
    navigate("operaciones");
  };

  const handlerProducts = (row) => {
    dispatch(setCurrentBag({ ...row, id: row._id }));
    navigate("productos/tae");
  };

  const handlerCheck = async ({ _id }, currentValue) => {
    const value = !currentValue;
    updateBagService(_id, { state: value })
      .then(() => dispatch(updateBag({ id: _id, data: { state: value } })))
      .catch((e) => errorAlert(e))
  };

  const handleSalePoints = (row) => {
    dispatch(setSalePointFilters({
      commerce: row.hoster._id,
      bag: row._id
    }))
    navigate("/cliente/puntos_de_venta")
  }

  const handlerAddSalePoint = (data) => {
    dispatch(setCurrentBag(data));
    navigate('/cliente/puntos_de_venta/crear')
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerFilter}
        defaultValues={filters}
        filters={[
          {
            name: 'commerce',
            label: "Comercio",
            type: "select",
            all: 'Todos',
            data: commerces.map(({ _id: id, username }) => ({ id, name: username })),
          },
        ]}
      />
      <TableData
        dataRows={bags}
        dataTitle={[
          "Comercio",
          "Tipo",
          "Nombre",
          "Comisión",
          "Ganacia",
          "Saldo",
          "Puntos de venta",
          "Estado",
        ]}
        formatter={({ _id, hoster, hashtag, earnings, earnings_scheme, saldo, salePoints, state }) => [
          `${hoster?.identification} ${hoster?.username}`,
          hoster?.type_commerce,
          hashtag,
          earnings,
          earnings_scheme,
          saldo ? currencyFormatter.format(saldo) : '',
          { name: "salePoints", type: "button", value: salePoints, action: handleSalePoints },
          { name: "state", type: "boolean", value: state, disabled: !validatePermission('bolsas', 'bolsas', ['editar']) }
        ]}
        onChecked={handlerCheck}
        actions={[
          {
            label: "Agregar punto de venta",
            icon: <Add />,
            onClick: (data) => handlerAddSalePoint(data)
          },
          validatePermission('bolsas', 'bolsas', ['editar']) && {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => handlerEdit(data),
          },
          validatePermission('bolsas', 'bolsas', ['editar']) && {
            label: "Operaciones",
            icon: <SettingsSuggestIcon />,
            onClick: (data) => handlerOperations(data),
          },
          validatePermission('bolsas', 'bolsas', ['editar']) && {
            label: "Productos",
            icon: <InventoryIcon />,
            onClick: (data) => handlerProducts(data),
          },
          validatePermission('bolsas', 'bolsas', ['editar']) && {
            label: "Eliminar",
            icon: <DeleteOutline />,
            onClick: (data) => handlerDelete(data),
          },

        ]}
      />
    </>
  )
}

export default BagScreen