import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorAlert } from "../../helpers/alerts";
import { getCommercesService } from "../../services/client/commerceService";
import BackButton from "../BackButton";
import InputSelectFieldGrid from "../common/InputSelectFieldGrid";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import { getBagsService } from "../../services/client/bagService";
import { setBags } from "../../redux/slices/client/bagSlice";
import { getSalePointTypesService } from "../../services/client/salePointService";
import { setCurrentSalePoint, setSalePointTypes } from "../../redux/slices/client/salePointSlice";
import { setCommerces } from "../../redux/slices/client/commerceSlice";
import CheckboxListFieldGrid from "../common/CheckboxListFieldGrid";
import SteepsBox from "components/common/SteepsBox";

const SalePointForm = ({ title, goBack, defaultValues, submit }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const watch = useWatch({ control });
  const { currentCommerce } = useSelector((store) => store.commerces);
  const { currentSalePoint, salePointTypes } = useSelector((store) => store.salePoint);
  const { commerce } = watch;

  useEffect(() => {
    getCommercesService(undefined, true)
      .then((response) => dispatch(setCommerces(response)))
      .catch((e) => errorAlert(e))
    getSalePointTypesService()
      .then((response) => dispatch(setSalePointTypes(response)))
      .catch((e) => errorAlert(e))

    return () => {
      dispatch(setCurrentSalePoint(null));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (commerce)
      getBagsService({ commerce })
        .then((response) => dispatch(setBags(response)))
        .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, [commerce]);

  const handlerSubmit = (data) => submit(data);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" >
        <div>
          <BackButton name={title} click={goBack} />
        </div>
        <div className='container-title'>
          <div className='commerce'>Comercio: {(currentSalePoint?.hoster || currentCommerce).username} </div>
        </div>
        <div>
        </div>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt={"40px"}>
        <Grid width={"70%"} container spacing={3}>
          <SteepsBox
            steps={[
              {
                label: "Datos del punto de venta",
                content: [
                  <InputTextFieldGrid
                    label={"Nombre"}
                    name={"name"}
                    required={"Debes agregar el nombre"}
                    xs={6}
                    control={control}
                  />,
                  <InputSelectFieldGrid
                    label={"Tipo"}
                    name={"type"}
                    required={"Debes seleccionar un tipo"}
                    xs={6}
                    control={control}
                    data={salePointTypes}
                    multiple={true}
                  />,
                  <InputTextFieldGrid
                    label={"Descripcion"}
                    name={"description"}
                    xs={6}
                    control={control}
                  />,
                  <InputTextFieldGrid
                    label={"Límite de saldo por día"}
                    name={"limit_amount_day"}
                    xs={6}
                    control={control}
                  />,
                  <InputTextFieldGrid
                    label={"Hora de apetura"}
                    name={"open_hour"}
                    xs={6}
                    inputType={"time"}
                    control={control}
                  />,
                  <InputTextFieldGrid
                    label={"Hora de cierre"}
                    name={"close_hour"}
                    xs={6}
                    inputType={"time"}
                    control={control}
                  />,
                  <CheckboxListFieldGrid
                    label={"Dias"}
                    name={"days"}
                    required={"Debes seleccionar por lo menos un dia"}
                    list={[
                      { label: "Lunes", name: "monday" },
                      { label: "Martes", name: "tuesday" },
                      { label: "Miercoles", name: "wednesday" },
                      { label: "Jueves", name: "thursday" },
                      { label: "Viernes", name: "friday" },
                      { label: "Sabado", name: "saturday" },
                      { label: "Domingo", name: "sunday" },
                    ]}
                    // xs={6}
                    control={control}
                  />
                ]
              },
              (!currentSalePoint || currentSalePoint.user) && {
                label: "Datos del usuario",
                content: [
                  <InputTextFieldGrid
                    xs={6}
                    name={"nameUser"}
                    control={control}
                    required={"Debes agregar el nombre"}
                    label={"Nombre(s)"}
                  />,
                  <InputTextFieldGrid
                    xs={6}
                    name={"lastnameUser"}
                    control={control}
                    required={"Debes agregar el apellidos"}
                    label={"Apellidos"}
                  />,
                  <InputTextFieldGrid
                    label={"Correo electrónico"}
                    name={"emailUser"}
                    required={"Debes agregar un correo electrónico válido"}
                    control={control}
                    pattern={{
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "El correo electrónico no es valido",
                    }}
                  />
                ]
              },
            ].filter(Boolean)}
          />
          <Grid container justifyContent="center" mt={5}>
            <Button
              sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
              onClick={handleSubmit(handlerSubmit)}
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SalePointForm;
