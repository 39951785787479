import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { ErrorOutline, CompareArrows } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { validatePermission } from 'helpers/authentication';
import { errorAlert } from 'helpers/alerts';
import { FilterBar, TableData } from "components";
import { setErrorCodesProvider, setErrorCodesHistoryProvider, setProviderErrorCodeToCreate, setErrorCodesHistoryIdsProvider } from 'redux/slices/admin/providerSlice';
import { getProviderCredsErrorCodesService, getProviderCredsErrorCodesHistoryService } from 'services/admin/providerService';

function ErrorCodesRecibidosScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const { current, errorCodesProvider, hebeErrorCodesHistoryList } = useSelector(store => store.providers)
  const errorCodesList = errorCodesProvider.map((e) => e.externalExceptionCode);

  useEffect(() => {
    getProviderCredsErrorCodesService(current.host)
      .then(response => dispatch(setErrorCodesProvider(response)))
      .catch(error => errorAlert(error));
  }, [update]);

  return (
    <>
      <FilterBar
        handlerFilter={async (data) => {
          getProviderCredsErrorCodesHistoryService(current.host, data)
            .then(response => dispatch(setErrorCodesHistoryProvider(response)))
            .catch(error => errorAlert(error));
        }}
        defaultValues={{
          date: [moment().subtract(30, 'days').toDate(), new Date()],
        }}
        allValue={null}
        filters={[
          {
            type: "dates",
            name: "date",
            label: "Fecha",
          },
          {
            name: 'state',
            label: "Estado",
            type: "select",
            data: [
              { name: "Todos" },
              { id: true, name: "Mapeados" },
              { id: false, name: "No Mapeados" },
            ],
          },
        ]}
      />
      <TableData
        dataRows={hebeErrorCodesHistoryList}
        headerButtons={[]}
        dataTitle={[
          "Homologado",
          "Error Code",
          "Message",
          "Count"
        ]}
        formatter={({ code, message, count }) => ([
          (errorCodesList.indexOf(code) > -1 ? "SI" : "NO"),
          code,
          message,
          count
        ])}
        actions={[
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Homologar",
            icon: <ErrorOutline />,
            validate: (data) => {
              return errorCodesList.indexOf(data.code) == -1
            },
            onClick: (data) => {
              dispatch(setProviderErrorCodeToCreate(data.code));
              navigate("create");
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Transacciones",
            icon: <CompareArrows />,
            validate: (data) => {
              return data.ids.length > 0
            },
            onClick: (data) => {
              dispatch(setErrorCodesHistoryIdsProvider(data.ids));
              navigate("/panel/transacciones");
            },
          },

        ]}
      />
    </>
  );
}


export default ErrorCodesRecibidosScreen;