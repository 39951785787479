import { EditOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { BackButton, CardBox } from "components";
import { errorAlert } from "helpers/alerts";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPINProduct, setCurrentPINSegment, setJustProducts, setPINProducts, setPINSegments } from "redux/slices/client/bagSlice";
import { getAllBagPINProductsService, getBagPINProductsService, getBagPINSegmentsService } from "services/client/bagService";

const BagPINScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hoster } = useSelector(store => store.session);
  const { currentCommerce } = useSelector((store) => store.commerces);
  const currentBag = currentCommerce.bags.find(bag => bag.type === 'PDS');
  const {
    justProducts,
    pinSegments,
    pinProducts,
    currentPINSegment,
    currentPINProduct
  } = useSelector((store) => store.bags);

  useEffect(() => {
    getBagPINSegmentsService(currentBag.id).then(list => {
      dispatch(setPINSegments(list));
    }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (justProducts) {
      getAllBagPINProductsService(currentBag.id).then(list => {
        dispatch(setPINProducts(list));
      }).catch(error => errorAlert(error))
    } else {
      if (currentPINSegment)
        getBagPINProductsService(currentBag.id, currentPINSegment.id).then(list => {
          dispatch(setPINProducts(list));
        }).catch(error => errorAlert(error))
    }
    // eslint-disable-next-line
  }, [currentBag, currentPINSegment, justProducts]);


  const handlerClickSegment = (segment) => {
    if (segment.id !== currentPINSegment?.id) {
      batch(() => {
        dispatch(setCurrentPINSegment(segment));
        dispatch(setCurrentPINProduct());
      })
    }
  };

  const handlerClickProduct = (product) => {
    if (product.id !== currentPINProduct?.id) {
      dispatch(setCurrentPINProduct(product));
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate('/cliente/comercios')} />
        </div>
        <div className="container-title">
          <div className="commerce">{currentCommerce.username} </div>
          <div className="bag">{currentBag.username} </div>
        </div>
        <div style={{ width: "170px" }}>
          <FormControlLabel
            label={'Ver solo produtos'}
            control={
              <Checkbox
                checked={justProducts}
                onChange={(e) => {
                  dispatch(setJustProducts(e.target.checked))
                }}
              />
            }
          />
        </div>
      </Grid>
      {
        !justProducts && (
          <CardBox
            name={"Segmentos"}
            values={pinSegments}
            handleSelect={handlerClickSegment}
            isSelect={currentPINSegment?.id}
            buttons={[
              hoster != currentCommerce._id && {
                icon: <EditOutlined  />,
                key: "segments",
                name: "Administrar",
                click: () => navigate("segmentos"),
              },
            ]}
            formatter={({ idSegment: item }) => ({
              ...item,
              image: `/v3/file/${item.fd}/`,
            })}
          />
        )
      }
      {
        (justProducts || currentPINSegment) && (
          <CardBox
            name={"Servicios"}
            values={pinProducts?.filter(({ state }) => state)}
            handleSelect={handlerClickProduct}
            isSelect={currentPINProduct?.id}
            buttons={[
              hoster != currentCommerce._id && {
                icon: <EditOutlined  />,
                key: "products",
                name: "Administrar",
                click: () => navigate("productos"),
              },
            ]}
            formatter={({ idProduct: item }) => ({
              ...item,
              image: `/v3/file/${item.fd}/`,
            })}

          />
        )
      }
    </div >
  );
};

export default BagPINScreen;
