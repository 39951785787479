import { AddOutlined, DeleteOutline, EditOutlined, Password } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomButton, FilterBar, TableData } from "../../../components";
import {
  removeUser,
  setCurrentUser,
  setUsers,
  updateUser,
} from "../../../redux/slices/security/userSlice";
import {
  getCommerceUsersService,
  recoverPassword,
  removeUserService,
  resetPassword,
  updateUserStateService,
} from "../../../services/security/userServices";


import { confirmAlert, errorAlert } from "../../../helpers/alerts";
import { validatePermission } from "../../../helpers/authentication";
import { setBags } from "../../../redux/slices/client/bagSlice";
import { setSalePoints } from "../../../redux/slices/client/salePointSlice";
import { getBagsService } from "../../../services/client/bagService";
import { getSalePointsService } from "../../../services/client/salePointService";
import { getCommercesService } from "../../../services/client/commerceService";
import { setCommerces } from "../../../redux/slices/client/commerceSlice";

const UserScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users } = useSelector((store) => store.users);
  const { commerces } = useSelector((store) => store.commerces);
  const { bags } = useSelector((store) => store.bags);
  const { salePoints } = useSelector((store) => store.salePoint);
  const session = useSelector(store => store.session);
  const [disabledBags, setDisabledBags] = useState(true)
  const [disabledSalePoints, setDisabledSalePoints] = useState(true)

  useEffect(() => {
    getCommercesService(undefined, true)
      .then((response) => dispatch(setCommerces(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => navigate("crear");

  const handlerCheck = (row, currentValue) => {
    const value = !currentValue;
    updateUserStateService(row.id, { state: value })
      .then(() => dispatch(updateUser({ id: row.id, data: { state: value } })))
      .catch((e) => errorAlert(e))
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentUser(row));
    navigate("editar");
  };

  const handlerDelete = (row) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar este usuario?",
      text: `El usuario ${row.nameUser} será eliminado.`,
      onConfirm: () => {
        removeUserService(row.id)
          .then(() => dispatch(removeUser(row.id)))
          .catch((e) => errorAlert(e))
      },
    });
  };

  const handlerFilter = (data) => {
    setDisabledBags([undefined, ''].includes(data.commerce))
    setDisabledSalePoints([undefined, ''].includes(data.bag))
    if (data.commerce)
      getBagsService(data)
        .then((response) => dispatch(setBags(response)))
        .catch((e) => errorAlert(e))
    if (data.bag)
      getSalePointsService(data)
        .then((response) => dispatch(setSalePoints(response)))
        .catch((e) => errorAlert(e))
    getCommerceUsersService(data)
      .then((response) => dispatch(setUsers(response)))
      .catch((e) => errorAlert(e))
  }

  const handlerResetPassword = ({ id, email, rol }) => {
    const sendEmail = !['H2H', 'Bolsa de Saldo'].includes(rol.name);
    confirmAlert({
      title: `¿Estas seguro que deseas restablecer la contraseña del usuario ${email}?`,
      text: sendEmail ?
        `Se enviará al usuario un correo electrónico con un enlace para restablecer` :
        `La contraseña se actulizará y mostrará la nueva contraseña solo una vez.`,
      onConfirm: () => {
        if (sendEmail)
          recoverPassword(email).then(() => {
            confirmAlert({
              icon: 'success',
              title: `¡Correo enviado exitosamente!`,
              showCancelButton: false,
            });
          }).catch((e) => errorAlert(e))
        else
          resetPassword(id).then(({ password }) => {
            confirmAlert({
              icon: 'success',
              title: `¡Se restablecio la contraseña exitosamente!`,
              html: `<b>Contraseña: </b> ${password}`,
              showCancelButton: false,
            });
          }).catch((e) => errorAlert(e))
      },
    });
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerFilter}
        filters={[
          {
            name: 'commerce',
            label: "Comercio",
            type: "select",
            all: 'Todos',
            data: commerces.map(({ _id: id, username }) => ({ id, name: username })),
            onSelect: (value, setValue) => {
              setValue('bag', undefined)
              setValue('salePoint', undefined)
            }
          },
          {
            name: 'bag',
            label: "Bolsa",
            type: "select",
            all: 'Todas',
            data: bags.map(({ _id: id, hashtag }) => ({ id, name: hashtag })),
            disabled: disabledBags,
            onSelect: (value, setValue) => {
              setValue('salePoint', undefined)
            }
          },
          {
            name: 'salePoint',
            label: "Punto de Venta",
            type: "select",
            all: 'Todos',
            data: salePoints.map(({ _id: id, name }) => ({ id, name })),
            disabled: disabledSalePoints
          },
        ]}

      />
      <TableData
        dataRows={users}
        headerButtons={[
          validatePermission('usuarios', 'usuarios', ['agregar']) &&
          <CustomButton
            icon={<AddOutlined />}
            key="addUser"
            name={"Crear Usuario"}
            click={handlerClick}
          />,
        ]}
        dataTitle={[
          "Comercio",
          "Punto de venta",
          "Usuario",
          "Nombre",
          "Rol",
          "MDD",
          "Estado",
        ]}
        formatter={({ hoster, sale, email, name, lastname, rol, mdd, state }) => [
          hoster?.username || '-',
          sale?.name || '-',
          email,
          name + " " + lastname,
          rol.name,
          mdd,
          { type: "boolean", value: state, disabled: !validatePermission('usuarios', 'usuarios', ['editar']) || hoster?.id === session.hoster },
        ]}
        onChecked={handlerCheck}
        actions={
          [
            validatePermission('usuarios', 'usuarios', ['editar']) &&
            {
              label: "Editar",
              icon: <EditOutlined />,
              onClick: (data) => handlerEdit(data),
              validate: (data) => data.hoster?.id !== session.hoster,
            },
            validatePermission('usuarios', 'usuarios', ['editar']) &&
            {
              label: "Restablecer Contraseña",
              icon: <Password />,
              onClick: (data) => handlerResetPassword(data),
              validate: (data) => data.hoster?.id !== session.hoster,
            },
            validatePermission('usuarios', 'usuarios', ['eliminar']) &&
            {
              label: "Eliminar",
              icon: <DeleteOutline />,
              onClick: (data) => handlerDelete(data),
              validate: (data) => data.hoster?.id !== session.hoster,
            },

          ]

        }
      />
    </>
  );
};

export default UserScreen;
