import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FilterBar, TableData } from '../../../../components';
import { errorAlert } from '../../../../helpers/alerts';
import { setPanel } from '../../../../redux/slices/route/routeSlice';
import { getPDSRuteoService, setPDSProviderCredentialService } from '../../../../services/roteo/routeService';
import { setLoading } from 'redux/slices/uiSlice';
import { getAllCredPDSProductsService } from 'services/admin/providerService';
import { setPDSProducts, setPDSSegments } from 'redux/slices/admin/productSlice';
import { getAllPDSProductsService, getPDSSegmentsService } from 'services/admin/productService';

const PDSRouteScreen = () => {
  const dispatch = useDispatch();
  const { panel } = useSelector(store => store.route)
  const { pdsProducts, pdsSegments } = useSelector((state) => state.products);
  const [providers, setProviders] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    getAllPDSProductsService()
      .then(data => dispatch(setPDSProducts(data)))
      .catch(error => errorAlert(error))
    getPDSSegmentsService()
      .then(data => dispatch(setPDSSegments(data)))
      .catch(error => errorAlert(error))
    getAllCredPDSProductsService()
      .then(response => setProviders(response))
      .catch(error => errorAlert(error))
    getPDSRuteoService()
      .then(response => dispatch(setPanel(response)))
      .catch(error => errorAlert(error));
    // eslint-disable-next-line
  }, []);
  
  const handlerSubmit = (values) => {
    if (!values.segment) return setRows(pdsProducts)
    setRows(pdsProducts.filter(({ idSegment }) => idSegment.id == values.segment))
  }

  const handlerChangePrimaryProvider = async (idCredential, product) => {
    dispatch(setLoading(true));
    setPDSProviderCredentialService({ idCredential, idProduct: product.id })
      .then(response => dispatch(setPanel(response)))
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)));
  }

  const setList = (product, otherProvider) => {
    const result = providers.filter(({ idProduct }) => (
      idProduct.id == product
    )).map(({ idCredential }) => ({
      id: idCredential.id,
      name: idCredential.name_connector
    }))
    return [
      { id: 'null', name: 'Sin asignar', operadoras: [] },
      ...result
    ]
  }
  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        allValue={null}
        filters={[
          {
            name: 'segment',
            label: "Segmento",
            type: "select",
            all: 'Todos',
            data: pdsSegments,
          }
        ]}
      />
      <TableData
        dataRows={rows.map(item => ({
          ...item,
          primaryProvider: panel?.find(({ product }) => product?.id == item?.id)?.primaryProvider,
        }))}
        headerButtons={[
        ]}
        dataTitle={[
          "Logo",
          "Nombre",
          "Segmento",
          "Proveedor Primario",
        ]}
        formatter={({ id, fd, name, idSegment, primaryProvider }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          idSegment.name,
          {
            name: "provider_primary",
            type: "select_button",
            label: 'Proveedor',
            list: setList(id),
            value: providers.length == 0 ? null : (primaryProvider || 'null'),
            onChange: handlerChangePrimaryProvider
          },
        ]}
      />
    </>
  )
}

export default PDSRouteScreen