import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, TableData } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { getPINProductsService } from "services/admin/productService";
import { setCredPINProductService } from "services/admin/providerService";
import { setCurrentPINProduct, setPINProducts as setProviderPINProducts } from "redux/slices/admin/providerSlice";
import { Grid } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { setPINProducts } from "redux/slices/admin/productSlice";

const CredPINProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    providerCredSelected,
    currentPINSegment,
    pinProducts: providerPINProducts,
  } = useSelector((store) => store.providers);
  const {
    pinProducts
  } = useSelector((state) => state.products);

  useEffect(() => {
    getPINProductsService(currentPINSegment.id)
      .then((data) => dispatch(setPINProducts(data)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerCheck = async (row, state) => {
    setCredPINProductService(providerCredSelected.id, currentPINSegment.id, row.id, !state).then((result) => {
      dispatch(setProviderPINProducts(result));
    }).catch(error => errorAlert(error))
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentPINProduct(row));
    navigate('editar');
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">Servicios</div>
          <div className="bag">{currentPINSegment.name} </div>
        </div>
        <div>
        </div>
      </Grid>
      <TableData
        dataRows={pinProducts.map((item) => {
          const service = providerPINProducts?.find(({ idProduct }) => idProduct.id === item.id);
          return {
            ...item,
            service
          };
        })}
        dataTitle={["Logo", "Nombre", "SKU", "Estado"]}
        formatter={({ fd, name, service }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          service?.sku,
          { name: "state", type: "boolean", value: service?.state, disabled: !service?.sku },
        ]}
        onChecked={handlerCheck}
        actions={[
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
        ]}
      />
    </>
  );
};

export default CredPINProductScreen;
