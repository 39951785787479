import React, { useEffect, useState } from 'react'
import { FilterBar, TableData } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setConciliacionesHistory } from '../../redux/slices/report/reportSlice';
import { getConciliacionHistoryService, getProvidersService } from '../../services/report/reportService';
import { errorAlert } from '../../helpers/alerts';
import moment from 'moment';
import ConciliationLogModal from 'components/modal/ConciliationLogModal';
import { ListAlt } from '@mui/icons-material';

const ConciliacionHistoryScreen = () => {
  const dispatch = useDispatch();
  const { conciliacionesHistory } = useSelector(store => store.reports);
  const [loading, setLoading] = useState(false)
  const [providers, setProviders] = useState([])
  const [current, setCurrent] = useState(null)

  const columns_diferencias = [
    { name: "provider", label: "Proveedor", format: ({ connectorName }) => connectorName },
    { name: "cred", label: "Instancia", format: ({ cred }) => cred.name_connector },
    { name: "message", label: "Mensaje", format: ({ message }) => message },
    { name: "positive", label: "Estatus", format: ({ positive }) => positive ? "Exitoso" : "Con errores" },
    { name: "createdAt", label: "Fecha", format: ({ createdAt }) => (new Date(createdAt)).toLocaleString() },
  ]

  useEffect(() => {
    setLoading(true)
    getProvidersService({})
      .then((response) => setProviders(response || []))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handlerSubmit = async (data) => {
    setLoading(true)
    getConciliacionHistoryService(data)
      .then((response) => dispatch(setConciliacionesHistory(response || [])))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        defaultValues={{
          date: [
            moment().subtract(1, 'days').toDate(),
            moment().toDate()
          ]
        }}
        filters={[
          {
            type: "dates",
            name: "date",
            label: "Fecha",
          },
          {
            name: 'provider',
            label: "Proveedores",
            type: "select",
            all: 'Todos',
            data: providers,
          }
        ]}
      />
      <TableData
        loading={loading}
        dataRows={conciliacionesHistory || []}
        dataTitle={columns_diferencias.map(({ label }) => label)}
        formatter={(data) => columns_diferencias.map(({ format }) => format(data))}
        actions={[
          {
            icon: <ListAlt />,
            label: 'Ver log',
            onClick: (data) => setCurrent(data._id)
          }
        ]}
      />
      <ConciliationLogModal
        id={current}
        open={Boolean(current)}
        onClose={() => setCurrent(null)}
      />
    </>

  )
}

export default ConciliacionHistoryScreen