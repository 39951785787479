import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { updateAlertService } from 'services/admin/alertsService';
import { updateAlert } from 'redux/slices/admin/alertSlice';
import AlertForm from 'components/admin/AlertForm';

const EditAlertScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentAlert: current } = useSelector((store) => store.alert)
  const values = {
    name: current.name,
    message: current.message,
    txnState: current.txnState,
    type: current.type,
    txns: current.txns,
    time: current.time,
    instance: current.instance || 0,
    emails: current.emails,
    numbers: current.numbers,
  }

  const handlerSubmit = async (data) => {
    updateAlertService(current.id, data).then((response) => {
      dispatch(updateAlert({ id: current.id, response }));
      navigate(-1);
    }).catch(error => errorAlert(error))
  };

  const validate = (data) => !hasSameValues(values, data)

  return (
    <AlertForm
      title="Editar Alerta"
      goBack={() => navigate(-1)}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
      system={current.type === "sistema"} 
    />
  );
}

export default EditAlertScreen