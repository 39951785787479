import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddCircleOutline, AddOutlined, Close, EditOutlined } from '@mui/icons-material';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { CustomButton, TableData } from 'components';
import { validatePermission } from 'helpers/authentication';
import BackButton from 'components/BackButton';
import { errorAlert, confirmAlert } from 'helpers/alerts';

import { setAmountProvider, setAmountSelected } from 'redux/slices/admin/providerSlice';
import { getProviderCredsAmountsService, deleteProviderCredsAmountsService } from 'services/admin/providerService';

function AmountsScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(0);
    const { amountProvider, providerCredSelected, hebeErrorCodesList } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Amounts de Proveedor'));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async () => {
            try {
                console.log("providerCredSelected", providerCredSelected.id);
                const response = await getProviderCredsAmountsService(providerCredSelected.id);
                dispatch(setAmountProvider(response));
            } catch (error) {
                errorAlert(error);
            }
        })();

        // eslint-disable-next-line
    }, [update]);

    const handlerDelete = ({ sku, id }) => {
        confirmAlert({
            title: "Estás seguro que deseas eliminar este amount?",
            text: `Amount: ${sku} será eliminada.`,
            onConfirm: async () => {
                try {
                    await deleteProviderCredsAmountsService(id);
                    setUpdate(update + 1);
                }
                catch (error) {
                    errorAlert(error);
                }
            }
        })
    };

    const handlerEdit = (row) => {
        dispatch(setAmountSelected(row));
        navigate("edit");
    };

    return (
        <TableData
            dataRows={amountProvider}
            headerButtons={[
                <BackButton
                    name={'Volver'}
                    click={() => {
                        navigate(-1);
                    }}
                />,
                validatePermission('proveedores', 'proveedores', ['agregar']) &&
                <CustomButton
                    icon={<AddOutlined />}
                    key="addProviderAmount"
                    name={"Nuevo Amount"}
                    click={() => {
                        navigate('create');
                    }}
                />,
            ]}
            dataTitle={[
                "Nombre",
                "Carrier Id",
                "Expose Id",
                "Amount",
                "SKU",
            ]}
            formatter={({ name, carrier_id, expose_id, amount, sku }) => ([
                name,
                carrier_id,
                expose_id,
                amount,
                sku
            ])}
            actions={[
                validatePermission('proveedores', 'productos', ['editar']) &&
                {
                    label: "Editar",
                    icon: <EditOutlined />,
                    onClick: (data) => {
                        handlerEdit(data);
                    },
                },
                validatePermission('proveedores', 'productos', ['editar']) &&
                {
                    label: "Eliminar",
                    icon: <Close />,
                    onClick: (row) => {
                        handlerDelete(row);
                    },
                },
            ]}
        />
    );
}

export default AmountsScreen;