import { CustomTab } from 'components';
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';

const AlertsScreen = () => {
  const navigate = useNavigate();

  return (
    <CustomTab
      panels={[
        {
          name: "Transaccionales",
          route: '/alertas/configuracion/transaccionales',
          onChange: () => navigate('transaccionales',),
          content: <Outlet />
        },
        {
          name: "Sistema",
          route: '/alertas/configuracion/sistema',
          onChange: () => navigate('sistema'),
          content: <Outlet />
        },
      ]}
    />

  )
}

export default AlertsScreen